import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "select",
      "style": {
        "position": "relative"
      }
    }}>{`Select`}</h1>
    <p>{`Select is a type of input that is used in forms, where a user is submitting data and chooses one option from a list. When text is inserted on the input field, filter the options. Consider showing suggestions when the select is active.`}</p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best practices`}</h2>
    <ul>
      <li parentName="ul">{`Order the list by frequency of use, and if applicable, in ascending order.`}</li>
      <li parentName="ul">{`For situations where the user can select more than one option, use using `}<a parentName="li" {...{
          "href": "/components/multi-select"
        }}>{`Multi Select`}</a>{`.`}</li>
      <li parentName="ul">{`Avoid using the select input of data that is known to the user, such as birth dates.`}</li>
    </ul>
    <h2 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}</h2>
    <Demo src='pages/components/select/Select' mdxType="Demo" />
    <h2 {...{
      "id": "suggestion",
      "style": {
        "position": "relative"
      }
    }}>{`Suggestion`}</h2>
    <p>{`Allow select value to be whatever the user types, by providing a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`createNewItem`}</code>{` prop.`}</p>
    <p>{`You can use the prop `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`openOnFocus`}</code>{` to control whether the suggestion menu should appear when input receives focus - default is false for suggestion selects (when createNewItem) and true for normal selects.`}</p>
    <Demo src='pages/components/select/Suggestion' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      